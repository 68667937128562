
import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readUserProfile, readHasAdminAccess } from '@/store/main/getters';
import {commitSwitchLanguage} from '@/store/admin/mutations';
import { readPatients, readLanguage } from '@/store/admin/getters';
import { dispatchGetPatients, dispatchGetPatientsNurse } from '@/store/admin/actions';
import ImageUploadComponent from "@/components/ImageUploadComponent.vue";
import { IUserProfile } from '@/interfaces';
import { api } from "@/api";
import { readToken } from "@/store/main/getters";

@Component({
  components: {
    ImageUploadComponent,
  },
})
export default class Dashboard extends Vue {

  public user_id = -1;
  public search = "";
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Lastname',
      sortable: true,
      value: 'lastname',
      align: 'left',
    },
    {
      text: ' Adress ',
      sortable: false,
      value: 'adress',
      align: 'left',
    },
  ];

  get greetedUser() {
    const userProfile = readUserProfile(this.$store);
    this.user_id = userProfile?.id as number;
    if (userProfile) {
      if (userProfile.firstname) {
        return (userProfile.firstname + " " + userProfile.lastname);
      } else {
        return userProfile.email;
      }
    }
  }
  
  get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  get patients() {
    return readPatients(this.$store);
  }

//Image----------------
    //for uploading the picture
  public imgDataUrl = "";

  public async b64Helper(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  private b64Cleaner(b64:string) {
    let newA: String = b64.replace(
        "dataimage/pngbase64",
        "data:image/png;base64,"
      );
      let newB: String = newA.replace(
        "dataimage/jpegbase64",
        "data:image/jpeg;base64,"
      );
      //Removing padding from backend
      let newC = newB.replace(
        "=",
        ""
      )
      return newC;
  }

  //---------------------------------------


  public async mounted() {
    const userProfile = readUserProfile(this.$store);
    if(readHasAdminAccess(this.$store)) {
      await dispatchGetPatients(this.$store);
    } else {
      await dispatchGetPatientsNurse(this.$store);
    } 
    if (userProfile) {
      var responseb64: string = "";
      await api
        .getProfilePicUser(
          readToken(this.$store),
          userProfile?.id as number
        )
        .then((response) => (responseb64= response.data))
        .catch((error) => console.log(error));
      this.imgDataUrl = this.b64Cleaner(responseb64);
    }
  }

  get language() {
    return readLanguage(this.$store);
  }
  
  public swtichLanguageEng() {
    console.log("Englisch");
    //True means eng
    if(this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public swtichLanguageDe() {
    //False means de
    console.log("DEUTSCH");
    if(!this.language) {
      commitSwitchLanguage(this.$store);
    }
  }
}
